<template>
  <v-container class="my-6 py-6">
    <v-alert
      v-if="!stripeAccountComplete"
      border="bottom"
      colored-border
      type="warning"
      elevation="2"
    >
      Debes completar tus datos de distribuidor y dar <strong>click en actualizar</strong>.
      Cuando lo hagas serás redirigido a Stripe para completar tus datos de pago.
    </v-alert>
    <v-alert
      v-else-if="!distributorBasicComplete"
      border="bottom"
      colored-border
      type="warning"
      elevation="2"
    >
      Es necesario dar de alta por lo menos una sucursal, un almacén y un tabulador de envío
    </v-alert>
    <datos-personales :show=stripeAccountComplete />
    <v-divider class="my-6" />
    <direcciones 
      :sucursales=sucursales 
      :distriId=distriId />
    <v-divider class="my-6" />
    <datos />
    <v-divider class="my-6" />
    <almacenes
      v-if="this.userData.tipo_usuario === 'Distribuidor'"
      :sucursales=sucursales
      :almacenes=almacenes
      :userData=userData 
      :distriId="distriId"
    />
    <v-divider
      v-if="!distributorBasicComplete"
      class="my-6" />
    <tabulador-envios v-if="!distributorBasicComplete" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { ApiAuth } from '@/api_utils/axios-base'

export default {
  metaInfo: {
    title: 'Mis datos'
  },
  data() {
    return {
      emailExists: "",
      sucursales: [],
      almacenes: [],
      distriId: null,
    }
  },
  components: {
    Direcciones: () => import('./Direcciones'),
    Datos: () => import('./Datos'),
    DatosPersonales: () => import('./DatosPersonales'),
    almacenes: () => import('./Almacenes'),
    TabuladorEnvios: () => import('./Distribuidor/TabuladorEnvios'),
  },
  computed: {
    ...mapState('usuario', ['userData', 'stripeAccountComplete']),
    ...mapGetters('usuario', ['distributorBasicComplete']),
  },
  methods: {
    ...mapActions('usuario', ['validateStripeDistributorAccount']),
    async getSucursales() {
      try {
        let response = await ApiAuth.get(`/personas/api/distribuidor/?usuario_id=${this.userData.id}`)
        let distriId = await response.data[0].id
        this.distriId = distriId

        let promiseResponse = await Promise.all([
          ApiAuth.get(`/personas/api/sucursal/?distribuidor_id=${distriId}`),
          ApiAuth.get(`/inventarios/api/almacenes/?distribuidor_id=${distriId}`)
        ])

        let sucursalesResponse = await Object.freeze(promiseResponse[0].data)
        this.sucursales = sucursalesResponse

        let almacenesResponse = await Object.freeze(promiseResponse[1].data)
        this.almacenes = almacenesResponse

        const userDataToUpdate = {...this.userData}
        if (sucursalesResponse.length) {
          if (sucursalesResponse.length === 1) {
            userDataToUpdate.sucursalId = sucursalesResponse[0].id
          } else {
            const suscursalPredeterminada = sucursalesResponse.find(sucursal => sucursal.sucursal_predeterminada === true)
            if (suscursalPredeterminada) {
              userDataToUpdate.sucursalId = suscursalPredeterminada.id
            }
          }
        }
        if (almacenesResponse.length) {
          if (almacenesResponse.length === 1) {
            userDataToUpdate.almacenId = almacenesResponse[0].id
          } else {
            const almacenPredeterminado = almacenesResponse.find(almacen => almacen.almacen_predeterminado === true)
            if (almacenPredeterminado) {
              userDataToUpdate.almacenId = almacenPredeterminado.id
            }
          }
        }
        if (sucursalesResponse.length || almacenesResponse.length) {
          this.$store.commit('usuario/updateUserData', userDataToUpdate, { root: true})
        }
      } catch(error) {
        console.error("error getting sucursales y almacenes")
      }
    },
    /*async validateStripeDistributorAccount() {
      try {
        if (this.userData.tipo_usuario === 'Distribuidor') {
          const response = 
            await ApiAuth.post('/personas/validar-cuenta-distribuidor-stripe', {id_distribuidor: this.userData.distribuidorId})
          const responseData = await response.data
          console.log("responseData valida stripe: ", responseData);
          if (responseData.error) {
            if (responseData.e[0] === 'email') {
              // Es muy probable que aun no se haya creado la cuenta de stripe
              this.stripeAccountComplete = false
            }
          } else {
            if (responseData.details_submmited) {
              this.stripeAccountComplete = true
            } else {
              this.stripeAccountComplete = false
            }
          }
        } else {
          this.stripeAccountComplete = true
        } 
      } catch (error) {
        console.error(error)
      }
    },*/
  },
  created() {
    this.emailExists = this.userData.email
    this.userData.tipo_usuario === "Distribuidor" ? this.getSucursales() : ''
    if (!this.stripeAccountComplete) {
      this.validateStripeDistributorAccount()
    }
  },
  beforeRouteLeave(from, to, next) {
    if (this.distributorBasicComplete) {
      next()
    } else {
      next({name: 'Mis datos'})
    }
  }
}
</script>
